import {FOCUSABLE_ELEMENT_SELECTOR} from "./constants";

export const getFocusableElements = (el: HTMLElement) =>
  Array.from(el.querySelectorAll<HTMLElement>(FOCUSABLE_ELEMENT_SELECTOR));

export const getFocusableChildren = (el: HTMLElement) =>
  getFocusableElements(el).filter(focusable => focusable !== el);

export const getFirstFocusableChild = (el: HTMLElement) => {
  const queried = el.querySelector(FOCUSABLE_ELEMENT_SELECTOR);
  return queried instanceof HTMLElement ? queried : null;
};

export const getLastFocusableChild = (el: HTMLElement) => {
  const focusableEles = getFocusableElements(el);
  return focusableEles[focusableEles.length - 1] || null;
};

export const getNextFocusableChild = (el: HTMLElement) => {
  const focusableEles = getFocusableChildren(el);
  const focusedEle = document.activeElement;
  const focusedIndex = focusedEle instanceof HTMLElement ? focusableEles.indexOf(focusedEle) : null;

  if (focusedIndex === null || focusedIndex === undefined) return null;

  const nextIndex = focusedIndex + 1 <= focusableEles.length - 1 ? focusedIndex + 1 : 0;
  return focusableEles[nextIndex];
};

export const getPreviousFocusableChild = (el: HTMLElement) => {
  const focusableEles = getFocusableChildren(el);
  const focusedEle = document.activeElement;
  const focusedIndex = focusedEle instanceof HTMLElement ? focusableEles.indexOf(focusedEle) : null;

  if (focusedIndex === null || focusedIndex === undefined) return null;

  const prevIndex = focusedIndex - 1 < 0 ? focusableEles.length - 1 : focusedIndex - 1;
  if (prevIndex === 0) console.log(focusableEles[prevIndex]);
  return focusableEles[prevIndex];
};
