import {useEffect, useState} from "react";

import {
  getFirstFocusableChild,
  getFocusableChildren,
  getLastFocusableChild,
  getNextFocusableChild,
  getPreviousFocusableChild,
} from "./utils";

export const useFocusScope = (shouldTrap: boolean, enableArrowKeys?: boolean) => {
  const [focusedFrom, setFocusedFrom] = useState<HTMLElement | null>(null);
  const [focusScope, setFocusScope] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const rememberPreviouslyFocusedEl = (e: FocusEvent) => {
      if (e.relatedTarget instanceof HTMLElement && !focusedFrom) {
        setFocusedFrom(e.relatedTarget);
      }
    };

    focusScope?.addEventListener("focusin", rememberPreviouslyFocusedEl);

    return () => {
      focusScope?.removeEventListener("focusin", rememberPreviouslyFocusedEl);
    };
  }, [focusedFrom, focusScope]);

  useEffect(() => {
    if (!focusScope) return;
    const firstFocusableChild = getFirstFocusableChild(focusScope);
    const lastFocusableChild = getLastFocusableChild(focusScope);
    const allOtherFocusableChildren = getFocusableChildren(focusScope).filter(
      child => child !== firstFocusableChild && child !== lastFocusableChild,
    );

    if (!firstFocusableChild || !lastFocusableChild)
      throw Error("Focus scope does not contain focusable elements.");

    const focusNext = (e: KeyboardEvent) => {
      if (focusScope && e.key === "ArrowDown" && !e.shiftKey) {
        e.preventDefault();
        getNextFocusableChild(focusScope)?.focus();
      }
    };

    const focusPrev = (e: KeyboardEvent) => {
      if (focusScope && e.key === "ArrowUp" && !e.shiftKey) {
        e.preventDefault();
        getPreviousFocusableChild(focusScope)?.focus();
      }
    };

    const preventWalkingOut = (e: KeyboardEvent) => {
      if (shouldTrap && firstFocusableChild && lastFocusableChild) {
        switch (e.key) {
          case "Tab": {
            if (!e.shiftKey) {
              e.preventDefault();
              firstFocusableChild.focus();
            }
            break;
          }
          case "ArrowDown": {
            e.preventDefault();
            firstFocusableChild.focus();
            break;
          }

          case "ArrowUp": {
            focusPrev(e);
          }
        }
      }
    };

    const preventBackingOut = (e: KeyboardEvent) => {
      if (shouldTrap) {
        switch (e.key) {
          case "Tab": {
            if (e.shiftKey) {
              e.preventDefault();
              lastFocusableChild.focus();
            }
            break;
          }

          case "ArrowUp": {
            e.preventDefault();
            lastFocusableChild.focus();
            break;
          }
          case "ArrowDown": {
            focusNext(e);
          }
        }
      }
    };

    const releaseFocus = () => {
      if (focusedFrom && !shouldTrap) {
        focusedFrom.focus();
        setFocusedFrom(null);
      }
    };

    const addEventListeners = () => {
      focusScope?.addEventListener("focusout", releaseFocus);
      lastFocusableChild?.addEventListener("keydown", preventWalkingOut);
      firstFocusableChild?.addEventListener("keydown", preventBackingOut);

      if (enableArrowKeys) {
        allOtherFocusableChildren.forEach(child => {
          child.addEventListener("keydown", focusNext);
          child.addEventListener("keydown", focusPrev);
        });
      }
    };

    const removeEventListeners = () => {
      focusScope?.removeEventListener("focusout", releaseFocus);
      lastFocusableChild?.removeEventListener("keydown", preventWalkingOut);
      firstFocusableChild?.removeEventListener("keydown", preventBackingOut);

      allOtherFocusableChildren.forEach(child => {
        child.removeEventListener("keydown", focusNext);
        child.removeEventListener("keydown", focusPrev);
      });
    };

    if (shouldTrap) {
      firstFocusableChild.focus();
      addEventListeners();
    } else {
      removeEventListeners();
    }

    return removeEventListeners;
  }, [shouldTrap, focusScope, focusedFrom, enableArrowKeys]);

  return setFocusScope;
};
